export const USER_PENDING = 'USER_PENDING';
export const COMMENT_UNREAD = 'COMMENT_UNREAD';

export const userPendingCount = count => dispatch =>
  dispatch({
    type: USER_PENDING,
    itemCount: count
  });

export const commentUnreadCount = count => dispatch =>
  dispatch({
    type: COMMENT_UNREAD,
    itemCount: count
  });
