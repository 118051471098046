import { createTheme } from '@material-ui/core';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import { ptBR } from '@material-ui/core/locale';

const theme = createTheme(
  {
    palette,
    typography,
    overrides: {
      ...overrides,
      MuiContainer: {
        ...overrides.MuiContainer,
        maxWidthLg: {
          maxWidth: '100% !important'
        }
      }
    }
  },
  ptBR
);

export default theme;
