import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Typography
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import { logout } from 'actions';
import { authLogout } from 'services/auth';
import { BlipChat } from 'blip-chat-widget';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  userName: {
    color: theme.palette.white,
    borderRight: '1px solid  #ffffff',
    paddingRight: 10,
    paddingLeft: 5
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  }
}));

function TopBar({ onOpenNavBarMobile, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const session = useSelector(state => state.session);

  // new BlipChat()
  //   .withAppKey(
  //     'aWd1YXRlbWlvbmU6ZmRiYzAyMmItNWYwOC00YWYwLWExMGUtYWJlOWViMTVlYTZj'
  //   )
  //   .withButton({ color: '#2CC3D5' })
  //   .withAccount({
  //     fullName: session.user.name,
  //     email: session.user.emailAddress
  //   })
  //   .build();

  const handleLogout = () => {
    dispatch(logout());
    authLogout();
    history.push('/auth/signin-oidc');
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="secondary"
            onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <img alt="Logo" src="/images/logos/logo-black.png" />
        </RouterLink>
        <div className={classes.flexGrow} />
        <PersonIcon />
        <Typography className={classes.userName} variant="subtitle1">
          {session.user.name}
        </Typography>

        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={handleLogout}>
          <InputIcon className={classes.logoutIcon} />
          Sair
        </Button>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
