export function groupBy(objectArray, property) {
  return objectArray.reduce(function(acc, obj) {
    var key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

export function removeItem(objectArray, item) {
  return objectArray.filter(function(ele) {
    return ele != item;
  });
}

export function hasAnyValue(list1, list2) {
  const L2Set = new Set(list2);
  return list1.some(L1element => L2Set.has(L1element));
}
