/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import MasterUnAuthLayout from 'layouts/MasterUnAuth';
import MasterLayout from 'layouts/Master';
import ErrorLayout from 'layouts/Error';
import PageCleanLayout from 'layouts/PageClean';

export default [
  {
    path: '/',
    exact: true,
    //component: () => <Redirect to="/stamps" />
    component: MasterLayout,
    routes: [
      {
        path: '/',
        exact: true,
        component: lazy(() => import('views/Redirect'))
      }
    ]
  },
  {
    path: '/auth',
    component: MasterUnAuthLayout,
    routes: [
      // {
      //   path: '/auth/login',
      //   exact: true,
      //   component: lazy(() => import('views/Auth/Login'))
      // },
      {
        path: '/auth/logout',
        exact: true,
        component: lazy(() => import('views/Auth/Logout'))
      },
      {
        path: '/auth/confirmation-register',
        exact: true,
        component: lazy(() => import('views/Auth/ConfirmationRegister'))
      },
      {
        path: '/auth/reset-password',
        exact: true,
        component: lazy(() => import('views/Auth/ResetPassword'))
      },
      {
        path: '/auth/signin-oidc',
        exact: true,
        component: lazy(() => import('views/Auth/Login/SigninOidc'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/preview',
    component: PageCleanLayout,
    routes: [
      {
        path: '/preview/template/:companyCode/:templateId',
        exact: true,
        component: lazy(() => import('views/Campaign/Preview'))
      },
      {
        path: '/preview/campaign/:campaignId',
        exact: true,
        component: lazy(() => import('views/Campaign/Preview'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/stamps',
    component: MasterLayout,
    routes: [
      {
        path: '/stamps',
        exact: true,
        component: lazy(() => import('views/Stamps/StampsList'))
      },
      {
        path: '/stamps/dashboard',
        exact: true,
        component: lazy(() => import('views/Campaign/Dashboard'))
      },
      {
        path: '/stamps/:campaignId/configuration',
        exact: true,
        component: lazy(() => import('views/Stamps/StampsConfiguration'))
      },
      {
        path: '/stamps/create',
        exact: true,
        component: lazy(() => import('views/Stamps/StampsList/CreateForm'))
      },
      {
        path: '/stamps/edit/:campaignId',
        exact: true,
        component: lazy(() => import('views/Stamps/StampsList/CreateForm'))
      }
    ]
  },
  {
    path: '/events',
    component: MasterLayout,
    routes: [
      {
        path: '/events/dashboard',
        exact: true,
        component: lazy(() => import('views/Events/Dashboard'))
      },
      {
        path: '/events/list',
        exact: true,
        component: lazy(() => import('views/Events/EventList'))
      },
      {
        path: '/events/create',
        exact: true,
        component: lazy(() => import('views/Events/EventForm'))
      },
      {
        path: '/events/edit/:eventId',
        exact: true,
        component: lazy(() => import('views/Events/EventForm'))
      },
      {
        path: '/events/banner/list',
        exact: true,
        component: lazy(() => import('views/Events/BannerList'))
      },
      {
        path: '/events/banner/create',
        exact: true,
        component: lazy(() => import('views/Events/BannerForm'))
      },
      {
        path: '/events/banner/edit/:bannerId',
        exact: true,
        component: lazy(() => import('views/Events/BannerForm'))
      },
      {
        path: '/events/participant/:ticketId/list',
        exact: true,
        component: lazy(() => import('views/Events/ParticipantList'))
      },
      {
        path: '/events/:eventId/participant/tickets/list',
        exact: true,
        component: lazy(() => import('views/Events/TicketList'))
      }
    ]
  },
  {
    path: '/stores',
    component: MasterLayout,
    routes: [
      {
        path: '/stores',
        exact: true,
        component: lazy(() => import('views/Store'))
      },
      {
        path: '/stores/create',
        exact: true,
        component: lazy(() => import('views/Store/CreateForm'))
      },
      {
        path: '/stores/edit/:storeId',
        exact: true,
        component: lazy(() => import('views/Store/CreateForm'))
      }
    ]
  },
  {
    path: '/service-desk',
    component: MasterLayout,
    routes: [
      {
        path: '/service-desk',
        exact: true,
        component: lazy(() => import('views/Customer'))
      },
      {
        path: '/service-desk/invoices',
        exact: true,
        component: lazy(() => import('views/Invoice'))
      },
      {
        path: '/service-desk/invoice-collections',
        exact: true,
        component: lazy(() => import('views/InvoiceCollection'))
      },
      {
        path: '/service-desk/procedures',
        exact: true,
        component: lazy(() => import('views/Procedures'))
      },
      {
        path: '/service-desk/procedures/:slug',
        exact: true,
        component: lazy(() => import('views/Procedures/Result'))
      }
    ]
  },
  {
    route: '*',
    component: MasterLayout,
    routes: [
      {
        path: '/campaigns',
        exact: true,
        component: lazy(() => import('views/Campaign/CampaignList'))
      },
      {
        path: '/campaign/create',
        exact: true,
        component: lazy(() => import('views/Campaign/Template'))
      },

      {
        path: '/campaign/:campaignId/customization/:templateId',
        exact: true,
        component: lazy(() => import('views/Campaign/Customization'))
      },
      {
        path: '/campaign/customization/:companyCode/:templateId',
        exact: true,
        component: lazy(() => import('views/Campaign/Customization'))
      },
      {
        path: '/campaign/:campaignId/configuration',
        exact: true,
        component: lazy(() => import('views/Campaign/Configuration'))
      },
      {
        path: '/users',
        exact: true,
        component: lazy(() => import('views/User/UserList'))
      },
      {
        path: '/users/:authorized',
        exact: true,
        component: lazy(() => import('views/User/UserList'))
      },
      {
        path: '/user/:page',
        exact: true,
        component: lazy(() => import('views/User/Management'))
      },
      {
        path: '/user/configuration/:userId',
        exact: true,
        component: lazy(() => import('views/User/Management'))
      },
      {
        path: '/setting/shoppings',
        exact: true,
        component: lazy(() => import('views/Setting/Company/CompanyList'))
      },
      {
        path: '/setting/seed-list',
        exact: true,
        component: lazy(() => import('views/Setting/SeedList'))
      },
      {
        path: '/setting/newsletter/spam',
        exact: true,
        component: lazy(() => import('views/Setting/Newsletter/Spam'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];
