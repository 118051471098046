import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Label from 'components/Label';
import UserService from 'services/user';
import { userPendingCount } from 'actions';

const useStyles = makeStyles(theme => ({
  root: {}
}));

function LabelAuthorizedCount({ className, ...rest }) {
  const classes = useStyles();
  const count = useSelector(state => state.count);
  const dispatch = useDispatch();

  useEffect(() => {
    let loaded = true;

    const getAuthorizedCount = async () => {
      const resAuthorizedCount = await UserService.getAuthorizedCount();

      if (loaded) {
        dispatch(userPendingCount(resAuthorizedCount));
      }
    };

    getAuthorizedCount();
    return () => {
      loaded = false;
    };
  }, []);

  if (count.userPeding === 0) {
    return null;
  }

  return (
    <Label color="#ff2d64" shape="rounded">
      {count.userPeding}
    </Label>
  );
}

LabelAuthorizedCount.propTypes = {
  className: PropTypes.string
};

export default LabelAuthorizedCount;
