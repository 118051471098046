import * as actionTypes from 'actions';

const initialState = {
  userPeding: 0,
  commentUnread: 0
};

const countReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_PENDING: {
      return {
        ...state,
        userPeding: action.itemCount
      };
    }
    case actionTypes.COMMENT_UNREAD: {
      return {
        ...state,
        commentUnread: action.itemCount
      };
    }
    default: {
      return state;
    }
  }
};

export default countReducer;
