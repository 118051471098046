import React from 'react';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MailIcon from '@material-ui/icons/MailOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import LabelAuthorizedCount from 'components/LabelAuthorizedCount';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import EventIcon from '@material-ui/icons/Event';

export default [
  {
    items: [
      {
        title: 'Service Desk',
        href: '/service-desk',
        icon: AccountBoxIcon,
        roles: ['Administrator', 'ServiceDeskOperator', 'ServiceDeskPromoter'],
        items: [
          {
            title: 'Clientes',
            href: '/service-desk',
            roles: [
              'Administrator',
              'ServiceDeskOperator',
              'ServiceDeskPromoter'
            ]
          },
          {
            title: 'Notas fiscais One',
            href: '/service-desk/invoices',
            roles: [
              'Administrator',
              'ServiceDeskOperator',
              'ServiceDeskPromoter'
            ]
          },
          {
            title: 'Notas fiscais Collections',
            href: '/service-desk/invoice-collections',
            roles: [
              'Administrator',
              'ServiceDeskOperator',
              'ServiceDeskPromoter'
            ]
          },
          {
            title: 'Lojas',
            href: '/stores',
            roles: ['Administrator', 'ServiceDeskOperator']
          },
          {
            title: 'Procedimentos',
            href: '/service-desk/procedures',
            roles: [
              'Administrator',
              'ServiceDeskOperator',
              'ServiceDeskPromoter'
            ]
          }
        ]
      },
      // {
      //   title: 'Campanha Comunicação',
      //   href: '/campaign',
      //   icon: MailIcon,
      //   roles: ['Administrator', 'Editor'],
      //   items: [
      //     {
      //       title: 'Lista de campanhas',
      //       href: '/campaigns'
      //     },
      //     {
      //       title: 'Criar campanha',
      //       href: '/campaign/create'
      //     }
      //   ]
      // },
      {
        title: 'Campanha Selos',
        href: '/stamps',
        icon: LabelOutlinedIcon,
        roles: ['Administrator', 'StampEditor'],
        items: [
          {
            title: 'Dashboard',
            href: '/stamps/dashboard'
          },
          {
            title: 'Lista de campanhas',
            href: '/stamps'
          },
          {
            title: 'Criar campanhas',
            href: '/stamps/create'
          }
        ]
      },
      {
        title: 'Eventos',
        href: '/events',
        icon: EventIcon,
        roles: ['Administrator', 'EventEditor'],
        items: [
          // {
          //   title: 'Dashboard',
          //   href: '/events/dashboard'
          // },
          {
            title: 'Lista de eventos',
            href: '/events/list'
          },
          {
            title: 'Criar evento',
            href: '/events/create'
          }
          // {
          //   title: 'Banners',
          //   href: '/events/banner/list'
          // },
          // ,
          // {
          //   title: 'Criar Banner',
          //   href: '/events/banner/create'
          // }
        ]
      },
      {
        title: 'Eventos',
        href: '/events',
        icon: EventIcon,
        roles: ['EventPromoter'],
        items: [
          {
            title: 'Lista de eventos',
            href: '/events/list'
          },
        ]
      },
      {
        title: 'Usuários',
        href: '/user',
        icon: PeopleIcon,
        roles: ['Administrator'],
        items: [
          {
            title: 'Lista de usuários',
            href: '/users'
          },
          {
            title: 'Usuários pendentes',
            href: '/users/authorized',
            label: () => (
              <LabelAuthorizedCount color="#ff2d64" shape="rounded" />
            )
          },
          {
            title: 'Meu perfil',
            href: '/user/profile'
          }
        ]
      },
      {
        title: 'Configurações',
        href: '/setting',
        icon: SettingsIcon,
        roles: ['Administrator'],
        items: [
          {
            title: 'Shoppings',
            href: '/setting/shoppings'
          },
          {
            title: 'Seed list',
            href: '/setting/seed-list'
          }
          // {
          //   title: 'Campanha',
          //   href: '/setting/newsletter/spam'
          // }
        ]
      }
    ]
  }
];
