import api from './api';

export class UserService {
  static get = async (pageSize, pageNumber) => {
    try {
      const response = await api.get(
        `/api/users?PageSize=${pageSize}&PageNumber=${pageNumber}`
      );
      if (response.data === null) {
        return null;
      }
      return response.data;
    } catch (ex) {
      throw new Error(ex);
    }
  };

  static getForAuthorized = async (pageSize, pageNumber) => {
    try {
      const response = await api.get(
        `/api/users/for-authorized?PageSize=${pageSize}&PageNumber=${pageNumber}`
      );
      if (response.data === null) {
        return null;
      }
      return response.data;
    } catch (ex) {
      throw new Error(ex);
    }
  };

  static getAuthorizedCount = async () => {
    try {
      const response = await api.get(`/api/users/authorized/count`);
      if (response.data === null) {
        return null;
      }
      return response.data.result;
    } catch (ex) {
      throw new Error(ex);
    }
  };

  static getUserByToken = async (tokenAd) => {
    try {
      const token = {
        tokenAd: tokenAd
      }

      const response = await api.post('/api/login/user',  token);
      if (response.data === null) {
        return null;
      }
      return response.data;
    } catch (ex) {
      throw new Error(ex);
    }
  };

  static getUserDetail = async () => {
    try {
      const response = await api.get('/api/users/profile');
      if (response.data === null) {
        return null;
      }
      return response.data.result;
    } catch (ex) {
      throw new Error(ex);
    }
  };

  static getUserById = async id => {
    try {
      const response = await api.get(`/api/users/${id}`);
      if (response.data === null) {
        return null;
      }
      return response.data.result;
    } catch (ex) {
      throw new Error(ex);
    }
  };

  static register = async user => {
    try {
      const response = await api.post('/api/users', user);
      if (response.data === null) {
        return null;
      }
      return response.data.result;
    } catch (ex) {
      throw new Error(ex);
    }
  };

  static registerAd = async (token, id, user) => {
    try {
      const register = {
        token: token,
        user: user
      };
      const response = await api.put(`/api/users/${id}/register-ad`, register);
      if (response.data === null) {
        return null;
      }
      return response.data.result;
    } catch (ex) {
      throw new Error(ex);
    }
  };

  static update = async (id, user) => {
    try {
      const response = await api.put(`/api/users/${id}`, user);
      if (response.data === null) {
        return null;
      }
      return response.data.result;
    } catch (ex) {
      throw new Error(ex);
    }
  };

  static updateProfile = async user => {
    try {
      const response = await api.put('/api/users/', user);
      if (response.data === null) {
        return null;
      }

      if (response.data.errors) {
        return response.data.errors;
      }
    } catch (ex) {
      throw new Error(ex);
    }
  };

  static forgotPassword = async email => {
    try {
      const response = await api.post(
        `/api/users/forgot-password?email=${email}`
      );
      if (response.data === null) {
        return null;
      }
      return response.data;
    } catch (ex) {
      throw new Error(ex);
    }
  };

  static resetPassword = async resetPassword => {
    try {
      const response = await api.post(
        '/api/users/reset-password',
        resetPassword
      );
      if (response.data === null) {
        return null;
      }
      return response.data;
    } catch (ex) {
      throw new Error(ex);
    }
  };

  static delete = async id => {
    try {
      const response = await api.delete(`/api/users/${id}`);
      if (response.data === null) {
        return null;
      }
      return response.data.result;
    } catch (ex) {
      throw new Error(ex);
    }
  };
}

export default UserService;
