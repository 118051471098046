/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer, List, ListSubheader, Hidden } from '@material-ui/core';
import NavItem from './NavItem';
import navConfig from './navConfig';
import { ChevronRight, ChevronLeft } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: props => (props.open ? 65 : 256),
    top: 64,
    height: 'calc(100% - 64px)'
  },
  navigation: {
    overflow: 'auto',
    padding: props => (!props.open ? theme.spacing(0, 0, 0, 2) : null),
    flexGrow: 1
  },
  toggleBtn: {
    width: 30,
    height: 30,
    marginRight: props => (props.open ? 6 : 0),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(0,0,0,0.2)',
    position: 'absolute',
    bottom: 10,
    right: 10,
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  checronIconStyle: {
    color: theme.palette.primary.main,
    fontSize: 30
  }
}));

function renderNavItems({
  items,
  subheader,
  key,
  userRoles,
  isSmallSideBar,
  toggleMenu,
  ...rest
}) {
  return (
    <List key={key}>
      {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            userRoles,
            isSmallSideBar,
            toggleMenu,
            ...rest
          }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  userRoles,
  isSmallSideBar,
  toggleMenu,
  depth = 0
}) {
  // if (item.roles && !item.roles.includes(userRole)) {
  const hasAnyValue = (list1, list2) => {
    const L2Set = new Set(list2);
    return list1.some(L1element => L2Set.has(L1element));
  };

  if (item.roles && !hasAnyValue(item.roles, userRoles)) {
    return acc;
  }

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.href}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
        isSmallSideBar={isSmallSideBar}
        toggleMenu={toggleMenu}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          userRoles: userRoles
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.href}
        label={item.label}
        title={item.title}
      />
    );
  }

  return acc;
}

function NavBar({
  openMobile,
  onMobileClose,
  className,
  toggleMenu,
  isOpenMenu,
  ...rest
}) {
  const classes = useStyles({ open: isOpenMenu });
  const location = useLocation();
  const session = useSelector(state => state.session);

  useEffect(() => {}, []);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <div onClick={toggleMenu} className={classes.toggleBtn}>
        {isOpenMenu ? (
          <ChevronRight className={classes.checronIconStyle} />
        ) : (
          <ChevronLeft className={classes.checronIconStyle} />
        )}
      </div>
      <nav className={classes.navigation}>
        {navConfig.map((list, index) =>
          renderNavItems({
            items: list.items,
            subheader: list.subheader,
            pathname: location.pathname,
            key: list.subheader + index,
            userRoles:
              session.user.id > 0
                ? session.user.userTypes.map(x => x.code)
                : '',
            isSmallSideBar: isOpenMenu,
            toggleMenu
          })
        )}
      </nav>
    </div>
  );

  return (
    <div>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer
          }}
          open
          variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </div>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
